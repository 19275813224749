import React from "react";

type Props = {
  buildUrl: (date: string, favorites: boolean) => string;
  date: string;
  dates: ScheduleDate[];
  disableFavorites: boolean;
  onlyFavorites: boolean;
  selectDate: (date: string) => void;
  selectFavorites: (value: boolean) => void;
  strings: Record<string, string>;
};

export default function Nav({
  date,
  dates,
  strings,
  buildUrl,
  selectDate,
  selectFavorites,
  onlyFavorites,
  disableFavorites
}: Props) {
  return (
    <nav className="subnav schedule-subnav">
      <div className="inner">
        <ul>
          {dates.map((d) => (
            <li key={d.date}>
              <a
                href={buildUrl(d.date, onlyFavorites)}
                className={d.date === date ? "current" : undefined}
                onClick={(evt) => {
                  evt.preventDefault();
                  selectDate(d.date);
                }}>
                {d.label}
              </a>
            </li>
          ))}
        </ul>
        {!disableFavorites && (
          <ul className="small">
            <li>
              <a
                href={buildUrl(date, false)}
                className={!onlyFavorites ? "current" : undefined}
                onClick={(evt) => {
                  evt.preventDefault();
                  selectFavorites(false);
                }}>
                {strings.allEvents}
              </a>
            </li>
            <li>
              <a
                href={buildUrl(date, true)}
                className={onlyFavorites ? "current" : undefined}
                onClick={(evt) => {
                  evt.preventDefault();
                  selectFavorites(true);
                }}>
                {strings.mySchedule}
              </a>
            </li>
          </ul>
        )}
      </div>
    </nav>
  );
}
