import React, { useState, useEffect, useRef } from "react";
import FavoriteEvents from "./FavoriteEvents";

type Props = {
  events: ScheduleEvent[];
};

export default function CatalogEvents({ events }: Props) {
  const favoriteEventsRef = useRef(
    new FavoriteEvents((events: number[]) => setFavorites(events))
  );
  const [favorites, setFavorites] = useState<number[]>(
    favoriteEventsRef.current.events
  );
  const refreshInterval = useRef<ReturnType<typeof setInterval> | null>(null);

  useEffect(() => {
    if (typeof setInterval !== "undefined") {
      refreshInterval.current = setInterval(() => {
        favoriteEventsRef.current.refresh();
      }, 500);
    }

    return () => {
      if (refreshInterval.current) {
        clearInterval(refreshInterval.current);
      }
    };
  }, []);

  const isFavorite = (event: ScheduleEvent) => {
    return favorites.includes(event.id);
  };

  const toggleFavorite = (event: ScheduleEvent) => {
    favoriteEventsRef.current.toggle(event.id);
  };

  const showFavorite = () => {
    return typeof window !== "undefined" && window.localStorage;
  };

  const renderEvent = (event: ScheduleEvent) => {
    const classes = ["event"];
    if (isFavorite(event)) {
      classes.push("is-favorite");
    }

    return (
      <div key={`event-${event.id}`} className={classes.join(" ")}>
        {showFavorite() && (
          <div
            className="favorite"
            onClick={(evt: React.MouseEvent) => {
              evt.preventDefault();
              toggleFavorite(event);
            }}></div>
        )}
        {event.day} {event.time}
        <br />
        {event.venue}
      </div>
    );
  };

  return (
    <div className="catalog-events">{events.map((e) => renderEvent(e))}</div>
  );
}
