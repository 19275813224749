import React, { useState, useCallback } from "react";

type Props = {
  strings: Record<string, string>;
  pages: Page[];
};

function fuzzyMatch(str: string, pattern: string) {
  const expression = pattern
    .toLowerCase()
    .split(" ")
    .reduce((a, b) => {
      return a + ".*" + b;
    });
  return new RegExp(expression).test(str.toLowerCase());
}

export default function SearchProgram({ strings, pages }: Props) {
  const [query, setQuery] = useState<string>("");

  const changeQuery = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      setQuery(evt.target.value);
    },
    []
  );

  const renderResult = useCallback((p: Page) => {
    return (
      <a href={p.url} className="result" key={p.id}>
        <h4>{p.path}</h4>
        <h2>{p.name}</h2>
      </a>
    );
  }, []);

  const results = useCallback(() => {
    const titles = pages.filter((p) => fuzzyMatch(p.name, query));
    const content = pages.filter((p) => fuzzyMatch(p.content, query));
    return [...new Set(titles.concat(content))];
  }, [pages, query]);

  const renderResults = useCallback(() => {
    if (query.length < 3) {
      return null;
    }

    return (
      <div className="results">{results().map((p) => renderResult(p))}</div>
    );
  }, [query, results, renderResult]);

  return (
    <div className="search-program">
      <form>
        <input
          name="query"
          type="text"
          value={query}
          onChange={changeQuery}
          placeholder={strings.placeholder}
        />
      </form>
      {renderResults()}
    </div>
  );
}
