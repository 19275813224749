import $ from "jquery";
import _ from "underscore";

$(function () {
  $("section.ads .elems-wrapper").each(function () {
    let points = 0;
    const newAds: HTMLElement[] = [];

    const adScore = (ad: HTMLElement) =>
      ad.classList.contains("wide") ? 2 : 1;

    const ads = $(this).find(".ad").get();
    $(this).find(".ad").remove();

    _.each(_.sample(ads, ads.length), (ad: HTMLElement) => {
      const newPoints = points + adScore(ad);
      if (newPoints <= 4) {
        newAds.push(ad);
        points = newPoints;
      }
    });

    newAds
      .sort((a, b) => adScore(b) - adScore(a))
      .forEach((ad) => $(this).append(ad));
  });
});
