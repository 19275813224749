import $ from "jquery";

$(function () {
  $(".slideshow").each(function () {
    const images = $(this).find(".slide").get();
    let currentImage: HTMLElement = null;
    let prevImage: HTMLElement = null;
    let index = 0;
    const effect = function () {
      prevImage = currentImage;
      currentImage = images[index];

      images.forEach((img) => {
        if (img === prevImage) {
          $(img).addClass("fx");
          $(img).addClass("prev");
        } else if (img === currentImage) {
          $(img).addClass("fx");
        } else {
          $(img).removeClass("fx");
          $(img).removeClass("prev");
        }
      });

      index = (index + 1) % images.length;
    };
    effect();
    setInterval(effect, 6000);
  });
});
