import $ from "jquery";

$(function () {
  $(".director-image img").each(function () {
    const img = this as HTMLImageElement;
    const imgDiv = document.createElement("div");
    imgDiv.classList.add("square-image");
    imgDiv.style.backgroundImage = "url('" + img.src + "')";
    img.parentNode.replaceChild(imgDiv, img);
  });
});
