import React from "react";

type Props = {
  disableFavorites: boolean;
  event: ScheduleEvent;
  favorite: boolean;
  formatTime: (date: Date) => string;
  overlaps: boolean;
  toggleFavorite: (event: ScheduleEvent) => void;
  slotDuration: number;
};

export default function EventItem({
  disableFavorites,
  event,
  favorite,
  formatTime,
  overlaps,
  toggleFavorite,
  slotDuration
}: Props) {
  const showFavorite = () =>
    !disableFavorites && typeof window !== "undefined" && window.localStorage;

  const startTime = () => new Date(Date.parse(event.startsAt));
  const endTime = () => new Date(Date.parse(event.endsAt));

  const slotCount = () => {
    const duration = (endTime().getTime() - startTime().getTime()) / 1000;
    return Math.ceil(duration / slotDuration);
  };

  const renderEventBody = () => (
    <div className="event-body">
      {showFavorite() && (
        <div
          className="favorite"
          onClick={(evt) => {
            evt.preventDefault();
            toggleFavorite(event);
          }}></div>
      )}
      <div className="time">
        {formatTime(startTime())}
        <br />
        {formatTime(endTime())}
      </div>
      <div className="name">
        <h3>{event.heading}</h3>
        <p>{event.name}</p>
        {event.description && <p>{event.description}</p>}
      </div>
      <div className="venue">{event.venue}</div>
    </div>
  );

  const classes = [
    "event",
    `event-${event.type}`,
    `slots-${slotCount()}`,
    overlaps && "overlaps",
    favorite && "is-favorite"
  ]
    .filter(Boolean)
    .join(" ");

  return event.url ? (
    <a className={classes} href={event.url}>
      {renderEventBody()}
    </a>
  ) : (
    <div className={classes}>{renderEventBody()}</div>
  );
}
