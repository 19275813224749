type Callback = (ids: number[]) => void;

export default class FavoriteEvents {
  callback?: Callback;
  events: number[];

  constructor(callback: Callback) {
    this.callback = callback;
    this.events = this.load();
  }

  compareArrays(a: number[], b: number[]) {
    return (
      a.length === b.length && a.every((value, index) => value === b[index])
    );
  }

  isFavorite(id: number) {
    return this.events.indexOf(id) !== -1;
  }

  triggerCallback() {
    if (this.callback) {
      this.callback(this.events);
    }
  }

  toggle(id: number) {
    this.events = this.load();
    if (this.isFavorite(id)) {
      this.events = this.events.filter((i) => i != id);
    } else {
      this.events.push(id);
    }
    this.save(this.events);
    this.triggerCallback();
  }

  load(): number[] {
    if (
      typeof window !== "undefined" &&
      window.localStorage &&
      window.localStorage.favoriteEvents
    ) {
      return JSON.parse(
        window.localStorage.favoriteEvents as string
      ) as number[];
    } else {
      return [];
    }
  }

  save(events: number[]) {
    if (window && window.localStorage) {
      window.localStorage.favoriteEvents = JSON.stringify(events);
    }
  }

  refresh() {
    const newEvents = this.load();
    if (!this.compareArrays(newEvents, this.events)) {
      this.events = newEvents;
      this.triggerCallback();
    }
  }
}
