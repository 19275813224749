import React, { useCallback, useState, useEffect, useRef } from "react";
import FavoriteEvents from "./FavoriteEvents";
import Nav from "./Schedule/Nav";
import Table from "./Schedule/Table";

type Props = {
  baseUrl: string;
  date: string;
  disableFavorites: boolean;
  schedule: FestivalSchedule;
  strings: Record<string, string>;
  slotDuration: number;
};

export default function Schedule({
  baseUrl,
  date,
  disableFavorites,
  schedule,
  strings,
  slotDuration
}: Props) {
  const dates = schedule.dates.map((d) => d.date);

  const favoriteEvents = useRef(
    new FavoriteEvents((events) => setFavorites(events))
  );
  const [selectedDate, setSelectedDate] = useState<string>(dates[0]);
  const [favorites, setFavorites] = useState<number[]>(
    favoriteEvents.current.events
  );
  const [onlyFavorites, setOnlyFavorites] = useState<boolean>(false);

  const refreshInterval = useRef<ReturnType<typeof setInterval> | null>(null);
  const day = () => schedule.dates.find((d) => d.date === selectedDate);

  const buildUrl = useCallback(
    (date: string, favorites: boolean) => {
      const filter = favorites ? "/favorites" : "";
      return `${baseUrl}/${date}${filter}`;
    },
    [baseUrl]
  );

  const selectDate = useCallback(
    (date: string, replace?: boolean) => {
      pushState(buildUrl(date, onlyFavorites), replace);
      setSelectedDate(date);
    },
    [buildUrl, onlyFavorites]
  );

  useEffect(() => {
    const defaultDate = () => {
      const today = new Date();
      if (date) {
        return date;
      } else if (
        today.toISOString &&
        dates.includes(today.toISOString().substring(0, 10))
      ) {
        return today.toISOString().substring(0, 10);
      } else {
        return dates[0];
      }
    };

    const relativePath = () => {
      if (typeof document === "undefined") {
        return null;
      } else {
        return document.location.pathname
          .replace(baseUrl, "")
          .replace(/^\//, "");
      }
    };

    const readStateFromLocation = () => {
      const path = relativePath();
      if (path) {
        const parts = path.split("/");
        setSelectedDate(parts[0]);
        setOnlyFavorites(parts[1] === "favorites");
      } else {
        selectDate(defaultDate(), true);
      }
    };

    readStateFromLocation();

    window.onpopstate = () => {
      readStateFromLocation();
    };

    if (typeof setInterval !== "undefined") {
      refreshInterval.current = setInterval(() => {
        favoriteEvents.current.refresh();
      }, 500);
    }

    return () => {
      if (refreshInterval.current) {
        clearInterval(refreshInterval.current);
      }
    };
  }, [date, dates, baseUrl, selectDate]);

  const pushState = (url: string, replace?: boolean) => {
    if (window && window.history && window.history.pushState) {
      if (replace) {
        window.history.replaceState(null, null, url);
      } else {
        window.history.pushState(null, null, url);
      }
    }
  };

  const selectFavorites = (onlyFavorites: boolean, replace?: boolean) => {
    pushState(buildUrl(selectedDate, onlyFavorites), replace);
    setOnlyFavorites(onlyFavorites);
  };

  const toggleFavorite = (event: ScheduleEvent) => {
    favoriteEvents.current.toggle(event.id);
  };

  const isFavorite = (event: ScheduleEvent) => favorites.includes(event.id);

  const filterEvents = (list: ScheduleEvent[]) => {
    return onlyFavorites ? list.filter((e) => isFavorite(e)) : list;
  };

  const currentDay = day();

  return (
    <section className="schedule">
      <Nav
        date={selectedDate}
        dates={schedule.dates}
        strings={strings}
        buildUrl={buildUrl}
        selectDate={selectDate}
        selectFavorites={selectFavorites}
        disableFavorites={disableFavorites}
        onlyFavorites={onlyFavorites}
      />
      <div className="inner">
        <Table
          allVenues={schedule.venues}
          events={currentDay?.events || []}
          filterEvents={filterEvents}
          isFavorite={isFavorite}
          disableFavorites={disableFavorites}
          toggleFavorite={toggleFavorite}
          slotDuration={slotDuration}
        />
      </div>
    </section>
  );
}
