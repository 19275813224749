import React, { useCallback, useMemo } from "react";
import EventItem from "./EventItem";

type Props = {
  allVenues: string[];
  disableFavorites: boolean;
  events: ScheduleEvent[];
  filterEvents: (events: ScheduleEvent[]) => ScheduleEvent[];
  isFavorite: (event: ScheduleEvent) => boolean;
  slotDuration: number;
  toggleFavorite: (event: ScheduleEvent) => void;
};

type Row = {
  time: Date;
  cells: ScheduleEvent[][];
};

function addTime(date: Date, seconds: number): Date {
  return new Date(date.getTime() + seconds * 1000);
}

function formatTime(date: Date): string {
  const zeroPad = (nNum: number, nPad: number) => {
    return ("" + (Math.pow(10, nPad) + nNum)).slice(1);
  };
  return [date.getHours(), zeroPad(date.getMinutes(), 2)].join(":");
}

function overlaps(event: ScheduleEvent, events: ScheduleEvent[]): boolean {
  const seconds = (d: string) => new Date(Date.parse(d)).getTime() / 1000;
  const startsAt = seconds(event.startsAt);
  const endsAt = seconds(event.endsAt);

  return (
    events
      .slice(0, events.indexOf(event))
      .filter((e) => e.venue === event.venue)
      .filter((e) => {
        return (
          (startsAt - (seconds(e.endsAt) - 1)) *
            (seconds(e.startsAt) - (endsAt - 1)) >=
          0
        );
      }).length > 0
  );
}

export default function Table({
  allVenues,
  disableFavorites,
  events,
  filterEvents,
  isFavorite,
  slotDuration,
  toggleFavorite
}: Props) {
  const startsAt = useMemo(() => {
    const d = new Date(Math.min(...events.map((e) => Date.parse(e.startsAt))));
    d.setMinutes(0);
    d.setSeconds(0);
    d.setMilliseconds(0);
    return d;
  }, [events]);

  const endsAt = useMemo(() => {
    return new Date(Math.max(...events.map((e) => Date.parse(e.endsAt))));
  }, [events]);

  const slots = useMemo(() => {
    const slots = [startsAt];
    while (slots[slots.length - 1] < endsAt) {
      slots.push(addTime(slots[slots.length - 1], slotDuration));
    }
    return slots;
  }, [startsAt, endsAt, slotDuration]);

  const venues = useMemo(() => {
    const eventVenues = events.map((e) => e.venue);
    return allVenues.filter((v) => eventVenues.includes(v));
  }, [allVenues, events]);

  const slotEvents = useCallback(
    (slot: Date, duration: number = slotDuration) => {
      return events.filter((e) => {
        const startsAt = new Date(Date.parse(e.startsAt));
        return startsAt >= slot && startsAt < addTime(slot, duration);
      });
    },
    [events, slotDuration]
  );

  const schedule = useMemo(() => {
    return slots.map((s) => ({
      time: s,
      cells: venues.map((v) => slotEvents(s).filter((e) => e.venue === v))
    }));
  }, [slots, venues, slotEvents]);

  const rowClasses = (row: Row): string[] => {
    const date = row.time;
    const classes: string[] = [];
    if (date.getMinutes() === 0) {
      classes.push("hour");
      if (slotEvents(date, 3600).length > 0) {
        classes.push("with-events");
      }
    }
    return classes;
  };

  const slotLabel = (date: Date): string => {
    const mins = date.getMinutes();
    return mins === 0 || mins === 30 ? formatTime(date) : "";
  };

  return (
    <div className="schedule-table">
      <div className="header row">
        <div className="time column"></div>
        {venues.map((v) => (
          <div className="venue column" key={"venue-header-" + v}>
            {v}
          </div>
        ))}
      </div>
      {schedule.map((row) => (
        <div
          key={row.time.getTime()}
          className={"content row " + rowClasses(row).join(" ")}>
          <div className="time column">
            <span className="timestamp">{slotLabel(row.time)}</span>
          </div>
          {row.cells.map((c) => (
            <div className="slot column" key={row.cells.indexOf(c)}>
              <div className="row-inner">
                {filterEvents(c).map((e) => (
                  <EventItem
                    key={"event-" + e.id}
                    event={e}
                    favorite={isFavorite(e)}
                    toggleFavorite={toggleFavorite}
                    slotDuration={slotDuration}
                    disableFavorites={disableFavorites}
                    formatTime={formatTime}
                    overlaps={overlaps(e, events)}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}
